import { storeToRefs } from 'pinia'

export function useAccountHelpers() {
  const accountStore = useAccountsStore()
  const { accounts, loading } = storeToRefs(useAccountsStore())

  if (!accounts.value.length && !loading.value)
    accountStore.list()

  const renderAccountsSelectList = computed(() => {
    return accounts.value.map((account) => {
      return {
        value: account.id,
        text: `[${account.account}] ${account.description}`,
      }
    })
  })

  return {
    renderAccountsSelectList,
  }
}
